var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('ylomi-logo')],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" L'aventure commence ici 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Remplissez les informations relatives à votre compte administrateur ! ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.applyAcceptInvitationAction.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Nom","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false : null,"placeholder":"Entrez votre nom"},model:{value:(_vm.payload.last_name),callback:function ($$v) {_vm.$set(_vm.payload, "last_name", $$v)},expression:"payload.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Prénom","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"prénom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false : null,"name":"register-username","placeholder":"Entrez votre prénom"},model:{value:(_vm.payload.first_name),callback:function ($$v) {_vm.$set(_vm.payload, "first_name", $$v)},expression:"payload.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Numéro de téléphone","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"numéro","rules":"required|min:8|regex:^(229)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"register-email","placeholder":"Ex: (229 xx xx xx xx)"},model:{value:(_vm.payload.phone_number),callback:function ($$v) {_vm.$set(_vm.payload, "phone_number", $$v)},expression:"payload.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Mot de passe","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"mot de passe","vid":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"Entrez votre mot de passe"},model:{value:(_vm.payload.password),callback:function ($$v) {_vm.$set(_vm.payload, "password", $$v)},expression:"payload.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Confirmation du mot de passe","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"confirmation","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"Retapez votre mot de passe"},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"disabled":_vm.isLoading,"variant":"primary","block":"","type":"submit"}},[(_vm.isLoading)?_c('div',[_c('span',[_vm._v(" Chargement ... ")]),_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v("Envoyer")])])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }